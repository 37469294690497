import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import styled from '@emotion/styled';

import { getTrackImageURL } from '../../../apis/users/hsf-university';

const StyledImageContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 160px;
  margin-bottom: 1.713rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 4px;
  height: 100%;
`;

type TrackImageProps = {
  name: string;
  imgId: string;
};

const TrackImage: React.FC<TrackImageProps> = ({ name, imgId }) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const getFileUrl = async (imgId: string) => {
      const file = await getTrackImageURL(imgId);
      if (file) setImageUrl(file);
    };
    if (imgId) getFileUrl(imgId);
  }, [imgId]);

  return (
    <StyledImageContainer>
      <StyledImage alt={name} src={imageUrl} />
    </StyledImageContainer>
  );
};

export default TrackImage;
