import React, { memo } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

import theme from '../../../theme';

const InfoRow = styled(Row)`
  color: ${theme.colorsBlack};
  font-size: 18px;
  text-align: center;
  top: 25%;
  width: 100%;
  position: absolute;
`;

const CareerInfoMessage = memo(function CareerInfoMessage() {
  return (
    <>
      <InfoRow align="middle" justify="space-around">
        <Col xs={24}>
          <b>You have no Careers selected yet</b>
        </Col>
        <Col xs={24}>After you select careers it will be listed here</Col>
      </InfoRow>
    </>
  );
});

export default CareerInfoMessage;
