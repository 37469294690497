import { FormStatus, IFormPayload, IFormSchema, IFormState } from '../../types/form';
import { IUser, RoleType } from '../../types/models/auth';
import { PROFILE_SCHEMA, getLookupsFromSchema } from './profile.schema';
import React, { useMemo, useState } from 'react';
import { editProfile, getProfile } from '../../apis/users/profile';
import { getRoles, getUser, useRoles } from '../../state/selectors/auth';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_CONFIRMATION_MESSAGE } from '../myhsf/form';
import FormRender from '../../components/forms/form-render';
import { IUserProfile } from '../../types/models/user-management';
import Loader from '../../components/common/loader';
import { Modal } from '../../components';
import { PageContainer } from '../../components/common/page-container';
import { RouteComponentProps } from '@reach/router';
import SEO from '../../components/seo';
import { createFormStateFromSchema } from '../../utils/form';
import { getMentorshipSection } from './mentorship/mentorship.section';
import { isEmpty } from 'lodash';
import { navigateTo } from '../../state/actions/routing';
import styled from '@emotion/styled';
import { useLookupByType } from '../../state/actions/lookup';
import { useQuery } from 'react-query';

const StyledPageContainer = styled(PageContainer)`
  width: 100%;
  padding: 0;
`;

function validateProfileFormState(currentFormState?: IFormState, currentFormSchema: IFormSchema = PROFILE_SCHEMA) {
  if (!currentFormState) {
    return createFormStateFromSchema(currentFormSchema);
  }
  const schemaSectionNumbers = currentFormSchema.sections.length;
  const formStateSectionsLength = currentFormState.sections.length;
  if (schemaSectionNumbers !== formStateSectionsLength) {
    return createFormStateFromSchema(currentFormSchema);
  }
  return currentFormState;
}

export default function Profile(_: RouteComponentProps) {
  const dispatch = useDispatch();
  const userInfo: IUser | null = useSelector(getUser);
  const roles: RoleType[] = useSelector(getRoles);
  const { isCoach, isMentor, isMentee } = useRoles();
  const [profilePayload, setProfilePayload] = useState<IFormPayload>();
  const [profileState, setProfileState] = useState<IFormState>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { success, error } = Modal;

  const CURRENT_PROFILE_SCHEMA = useMemo(() => {
    let sections = [...PROFILE_SCHEMA.sections];

    if (isCoach || isMentor || isMentee) {
      sections = sections.filter(section => section.title !== 'MentorMatch & Career Coach');
      sections.push(getMentorshipSection(roles));
    }

    return { sections } as IFormSchema;
  }, [isCoach, isMentor, isMentee, roles]);

  const lookupsNames = getLookupsFromSchema(CURRENT_PROFILE_SCHEMA);
  const { isLoading: isLookupsLoading } = useLookupByType(lookupsNames);

  const { isLoading: isLoadingUserProfile, error: errorLoadingUserProfile } = useQuery(
    !isEmpty(userInfo) && getProfile.QUERY_KEY,
    () => getProfile(userInfo?.id, true),
    {
      onSuccess: (data: IUserProfile) => {
        if (data && userInfo) {
          setProfilePayload({
            data: data.profilePayload?.data ? { email: data.email, ...data.profilePayload?.data } : {},
            encryptFields: data.profilePayload?.encryptFields,
          });
          setProfileState(validateProfileFormState(data.profileState, CURRENT_PROFILE_SCHEMA));
        }
      },
      onError: err => {
        setErrorMessage(err.message);
      },
    }
  );

  const isReady = !!profilePayload && !!profileState;

  const saveProfileData = async (userId: string, profilePayload: IFormPayload, profileState: IFormState) => {
    try {
      await editProfile(userId, { profilePayload, profileState });
      success({
        title: 'Your profile data has been successfully saved',
        onOk() {
          dispatch(navigateTo('/myhsf'));
        },
      });
    } catch (err) {
      error({ title: 'Oops, something went wrong while saving your data. Please try again' });
    }
  };

  const renderProfileForm = () => {
    if (isLoadingUserProfile || isLookupsLoading) {
      return <Loader />;
    }

    if (errorLoadingUserProfile) {
      return <p>{`Error loading User Profile data`}</p>;
    }

    return (
      isReady &&
      userInfo && (
        <FormRender
          validateOnSave
          schema={CURRENT_PROFILE_SCHEMA}
          initialData={profilePayload}
          initialFormState={profileState}
          formStatus={FormStatus.InProgress}
          isSubmitted={false}
          isLoading={isLoadingUserProfile}
          isReady={isReady}
          isSaving={false}
          errorMessage={errorMessage}
          confirmationMessage={DEFAULT_CONFIRMATION_MESSAGE}
          isDebug={false}
          onSubmit={async (profilePayload, profileState) => {
            saveProfileData(userInfo.id, profilePayload, profileState);
          }}
          onSaveForm={async (profilePayload, profileState) => {
            saveProfileData(userInfo.id, profilePayload, profileState);
          }}
          onConfirmationClose={() => ({})}
        />
      )
    );
  };

  return (
    <>
      <SEO title="Profile" />
      <StyledPageContainer>{renderProfileForm()}</StyledPageContainer>
    </>
  );
}
