import React, { memo, useCallback, useMemo } from 'react';
import { Row, Col, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { Input, Select } from '../../../components/forms';
import theme from '../../../theme';

const FiltersContainer = styled(Row)`
  margin: 0 auto;
  width: 100%;
  background-color: white;
`;

const FilersContent = styled(Row)`
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.screenXl};
  height: auto;
  min-height: 10vh;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;

const SearchCol = styled(Col)`
  @media (max-width: ${theme.screenSmMax}) {
    margin-top: 5%;
  }
`;

const FilterText = styled.h3`
  float: right;
  margin-right: 10px;
  @media (max-width: ${theme.screenSmMax}) {
    float: left;
  }
`;

const StyledFormItem = styled(Form.Item)`
  @media (max-width: ${theme.screenXsMax}) {
    margin-bottom: 0px;
  }
`;

type Props = {
  filters: any;
  majors: any;
  handleOnChange: (value: any) => void;
  handleOnBlur?: (value: any) => void;
  handleSearch: (data: any) => void;
};

const CareerFilter = memo(function CareerFilter({ filters, handleOnChange, handleSearch, majors }: Props) {
  const majorOptions = useMemo(() => {
    return majors && [].concat(majors.map((item: any) => ({ label: item.displayText, value: item.id })));
  }, [majors]);

  const handleEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSearch(filters);
      }
    },
    [filters, handleSearch]
  );

  const onChange = useCallback(
    e => {
      handleOnChange && handleOnChange(e);
      const { name, value } = e;
      const newFilterValue = { ...filters, [name]: value };
      handleSearch(newFilterValue);
    },
    [filters, handleOnChange, handleSearch]
  );

  return (
    <>
      <FiltersContainer>
        <FilersContent>
          <Row justify="space-between" align="bottom" gutter={[16, 16]}>
            <SearchCol xs={24} md={8}>
              <StyledFormItem>
                <Input
                  onKeyPress={handleEnter}
                  prefix={<SearchOutlined />}
                  name={'searchText'}
                  value={filters.searchText}
                  onChange={handleOnChange}
                />
              </StyledFormItem>
            </SearchCol>

            <Col xs={24} md={8}>
              <Row align="bottom" justify="center">
                <Col xs={0} md={6}>
                  <Form.Item>
                    <FilterText>Filter By:</FilterText>
                  </Form.Item>
                </Col>

                <Col xs={24} md={18}>
                  <Form.Item label={'Major:'}>
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Please select one or more"
                      name={'major'}
                      options={majorOptions}
                      value={filters.major}
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option &&
                        option.props !== null &&
                        option.props.children !== null &&
                        typeof option.props.children === 'string' &&
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </FilersContent>
      </FiltersContainer>
    </>
  );
});

export default CareerFilter;
