import React, { memo, useCallback, useState } from 'react';
import { Row, Col, Card } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import CareerDetailModal from './career-detail-modal';
import MajorCategoriesModal from './career-major-modal';
import { icon } from './styles';

import theme from '../../../theme';
import { Button } from '../../../components';
import { Career } from '../../../types/models/discernment-survey';

const StyledButton = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  height: 320px;
  position: relative;
`;

const WrapperContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const StyledCard = styled(Card)`
  border-radius: 10px;
  font-size: 16px;
  color: ${theme.colorsBlack};
  .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    white-space: inherit;
  }
`;

const TitleRow = styled(Row)`
  min-height: 50px;
  color: ${theme.colorsBlack};
  font-weight: bold;
`;

const DescriptionRow = styled(Row)`
  margin-top: 10px;
`;
const iconSize = 40;
const StyledIconContainer = styled.div`
  background: #dfe4ea;
  border-radius: 2px;
  width: ${iconSize}px;
  height: ${iconSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled.img`
  width: ${iconSize * 0.7}px;
`;

type Props = {
  item: Career;
  select: Function;
  unselect: Function;
  getMajorCategories: (id: string) => Promise<any>;
};

const DEFAULT_CHAR_COUNT = 200;

const getTruncatedText = (longText: string, maxCharCount: number) => {
  return longText.substring(0, maxCharCount) + '...';
};

const CareerCard = memo(function CareerCard({ item, select, unselect, getMajorCategories }: Props) {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>();

  const fechDataAndOpenModal = useCallback(
    async (id: string) => {
      const data = await getMajorCategories(id);
      setDialogData(data);
      setVisibleDialog(true);
    },
    [getMajorCategories]
  );

  return (
    <>
      <StyledCard
        title={
          <TitleRow justify="center" align="middle">
            <Col xs={4}>
              <StyledIconContainer>
                <StyledIcon src={icon(item.majors.length ? item.majors[0].name : '')} alt={item.majors[0].name} />
              </StyledIconContainer>
            </Col>
            <Col xs={20}>{item.title}</Col>
          </TitleRow>
        }
      >
        <Wrapper>
          <Row>
            <Col xs={24}>
              <b>Related Major Category:</b>
            </Col>
            <Col xs={24}>
              {item.majors
                .map<React.ReactNode>((elem: any) => {
                  return (
                    <React.Fragment key={`btn-wrap-${elem.id}-${item.id}`}>
                      <Button
                        key={`btn-${elem.id}-${item.id}`}
                        onClick={() => {
                          fechDataAndOpenModal(elem.id);
                        }}
                        type="link"
                      >
                        {`${elem.name}`}
                      </Button>
                    </React.Fragment>
                  );
                })
                .reduce((prev, curr) => [prev, ', ', curr])}
            </Col>
          </Row>

          <DescriptionRow>
            <Col xs={24}>{item.description && getTruncatedText(item.description, DEFAULT_CHAR_COUNT)}</Col>
            <Col xs={24}>
              <CareerDetailModal data={item} select={select} unselect={unselect} />
            </Col>
          </DescriptionRow>

          <WrapperContent>
            <Row justify="space-between" align="middle">
              {item.selected ? (
                <>
                  <Col xs={24} sm={12}>
                    <StyledButton disabled={item.selected} type="secondary">
                      <p style={{ textDecoration: 'none' }}>
                        <CheckOutlined />
                        &nbsp;Selected
                      </p>
                    </StyledButton>
                  </Col>
                  <Col xs={24} sm={12}>
                    <StyledButton onClick={() => unselect()}>Unselect</StyledButton>
                  </Col>
                </>
              ) : (
                <Col xs={24}>
                  <StyledButton key={`select-button-${item.id}`} type="primary-blue" onClick={() => select()}>
                    Select Career
                  </StyledButton>
                </Col>
              )}
            </Row>
          </WrapperContent>
        </Wrapper>
        {visibleDialog && (
          <MajorCategoriesModal
            data={dialogData}
            handleCancel={() => {
              setVisibleDialog(false);
            }}
          />
        )}
      </StyledCard>
    </>
  );
});

export default CareerCard;
