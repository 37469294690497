import React from 'react';
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../../components';
import { getInitials } from '../../../../utils/users';
import { formatDate } from '../../../../utils/date';
import { clear } from '../../../../state/actions/message';
import { getIsLoadingChat, getUserIdForChat } from '../../../../state/selectors/message';

const SectionWrapper = styled.div`
  height: 130px;
  display: flex;
`;

const StyledSection = styled.div`
  display: inline;
  margin: 20px 0;
`;

const StatusSection = styled.div`
  font-size: 15px;
  color: black;
  margin: 5px 0;
`;

const DateSection = styled.div`
  font-size: 15px;
  opacity: 0.7;
`;

const NameSection = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: black;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const StyledButton = styled(Button)`
  margin: 0 10px;
`;

const UserAvatar = styled(Avatar)`
  color: #f56a00;
  background-color: #fde3cf;
  margin: 30px 20px;
`;

const DATE_FORMAT = 'MMM DD, YYYY';

const UserDetailsContainer = ({ userData, buttonsList }: any) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  return (
    <SectionWrapper>
      <UserAvatar size={75} src={userData.image}>
        {getInitials(userData)}
      </UserAvatar>
      <StyledSection>
        <NameSection>{userData.firstName + ' ' + userData.lastName}</NameSection>
        <StatusSection>{userData.programName || 'Mentorship Program'}</StatusSection>
        <DateSection>
          {'Matched ' + formatDate(userData.matchDate, DATE_FORMAT)}
          {userData.completedDate && (
            <>
              <strong> •</strong> {'Milestones Completed ' + formatDate(userData.completedDate, DATE_FORMAT)}
            </>
          )}
        </DateSection>
      </StyledSection>
      <Actions>
        {buttonsList &&
          buttonsList.map((button: any) => {
            const isLoading = isLoadingChat && chatUserId === userData?.id && button.opensChat;
            return (
              <StyledButton
                key={button.title}
                type={button.type}
                onClick={() => {
                  dispatch(clear());
                  return button.handler(userData?.id);
                }}
                loading={isLoading}
              >
                {button.title}
              </StyledButton>
            );
          })}
      </Actions>
    </SectionWrapper>
  );
};

export default UserDetailsContainer;
